import { MDSText } from '@exploration/brandRefreshTheme';
import {
  Container as ExplorationContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { ArrowRight } from '@midwest/web/base';
import { MDSAnchorButton } from '@midwest/web/button';
import { Link } from '@midwest/web/link';
import { desktop, tablet, textBodyRegDefault } from '@thrivent/midwest-shared';
import Image from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)`
  background-color: ${(p) => p.theme.midwestColors.white};

  ${desktop} {
    position: relative;
  }
`;

export const Container = styled(ExplorationContainer)`
  padding: 3rem 1rem 4rem;
  ${desktop} {
    padding: 4rem 1rem 4rem;
    position: relative;
  }
`;

export const ModalContainer = styled.div`
  ${desktop} {
    position: relative;
  }
`;

export const ArticleContainer = styled.div<{ $modalActive: boolean }>`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  row-gap: 0rem;
  margin-top: 2.5rem;
  border-radius: 10px;
  border: 2px solid ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};

  button:last-child {
    border: none;
    border-radius: 0px 0px 10px 10px;
  }

  ${tablet} {
    position: relative;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    border: none;
    ${(p) =>
      p.$modalActive
        ? ``
        : `button:last-child {
        border: 2px solid ${p.theme.midwestColors.identityBrandPrimaryHeavy};
        border-radius: 10px;
      }`}
  }
  ${desktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ArticleImage = styled(Image)`
  object-fit: cover;
  transition: transform 300ms ease-in-out;
`;

export const ArticleButton = styled.div`
  padding: 0.5rem;
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  appearance: none;
  width: 100%;
  background: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;

  ${desktop} {
    padding: 1.5rem;
  }
`;

export const ArticleButtonText = styled(MDSText.BodyHeavyXs)`
  ${desktop} {
    font-size: 1.25rem;
  }
  font-size: 1rem;
`;

export const Article = styled.button<{ $modalActive: boolean }>`
  height: 4.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.theme.midwestColors.backgroundDefault};
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  overflow: hidden;
  appearance: none;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  padding: 0;
  margin: 0;

  ${tablet} {
    height: 7.5rem;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
  }
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0);
  opacity: ${(p) => (p.$modalActive ? '.25' : '1')};
  transition:
    ${(p) => (p.$modalActive ? 'opacity 300ms ease-in-out' : 'opacity 0ms')},
    box-shadow 300ms ease-in-out;

  &:hover,
  &:focus {
    background-color: ${(p) =>
      p.theme.midwestColors.identityBrandTertiaryFaint};
    text-decoration: underline;
    text-decoration-color: ${(p) =>
      p.theme.midwestColors.identityBrandPrimaryHeavy};
  }
`;

export const ArticleImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 4.625rem;
  width: 122px;
  background-color: transparent;
  overflow: hidden;
  margin-left: 0.7rem;
  ${tablet} {
    height: 7.5rem;
  }
`;

export const CardIcon = styled.span`
  width: 3rem;
  svg {
    width: 100%;
    height: auto;
  }
  ${desktop} {
    width: 3.75rem;
  }
`;

export const RightArrowButton = styled(ArrowRight)`
  height: 1rem;
  width: 1rem;
  fill: ${(p) => p.theme.midwestColors.textSecondary};
  margin-left: 0.25rem;
`;

export const RightArrowLink = styled(ArrowRight)`
  height: 0.75rem;
  width: 0.75rem;
  fill: #be1d3d;
  margin-left: 0.25rem;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  appearance: none;
  background: none;
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const ModalImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: none;

  ${desktop} {
    width: 20rem;
    display: block;
  }
`;

export const ModalImage = styled(Image)`
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: top;

  ${tablet} {
    object-position: inherit;
  }
`;

export const ModalTextContainer = styled.div`
  padding: 3.5rem 2.5rem 1rem;
  display: flex;
  opacity: 0;
  flex-direction: column;

  ${tablet} {
    flex-direction: row;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Modal = styled.dialog<{ open: boolean }>`
  position: fixed;
  z-index: 599;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  ${tablet} {
    z-index: 2;
    position: absolute;
    top: unset;
    left: unset;
    max-height: 36rem;
    flex-direction: row;
  }

  ${desktop} {
    max-height: 28rem;
  }

  background-color: ${(p) => p.theme.midwestColors.backgroundDefault};
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transform-origin: bottom;
  border: none;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: translateY(1rem) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  &.open {
    display: flex;
    pointer-events: all;
    @media (prefers-reduced-motion) {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    @media (prefers-reduced-motion: no-preference) {
      animation: pop-in 300ms ease-out 0ms;
      animation-fill-mode: forwards;
    }

    ${ModalTextContainer} {
      @media (prefers-reduced-motion) {
        opacity: 1;
        transform: translateY(0);
      }
      @media (prefers-reduced-motion: no-preference) {
        animation: fade-up 500ms ease-out 350ms;
        animation-fill-mode: forwards;
      }
    }
  }
`;

export const ModalTextMain = styled.div`
  ${tablet} {
    max-width: 27.5rem;
    padding-right: 1rem;
  }
`;

export const ModalTitle = styled(MDSText.TitleMd)`
  color: ${(p) => p.theme.midwestColors.textSecondary};
  font-weight: 400;
`;

export const ModalDescription = styled(MDSText.BodyRegDefault)`
  margin-top: 0.75rem;
  color: #203437;

  sup {
    vertical-align: top;
    position: relative;
    top: -0.25rem;
  }
`;

export const ModalListTitle = styled(MDSText.BodyHeavyDefault)`
  margin-top: 0.5rem;
  color: ${(p) => p.theme.midwestColors.textSecondary};

  ${tablet} {
    margin-top: 1.75rem;
  }
`;

export const ModalSidebarListTitle = styled(MDSText.BodyHeavyDefault)`
  color: ${(p) => p.theme.midwestColors.textSecondary};

  ${tablet} {
    margin-top: 0.5rem;
  }
`;

export const ModalList = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 2rem;
`;

export const ModalListItem = styled(MDSText.BodyRegSm)`
  color: #203437;
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 1rem;

  sup {
    margin-top: -6px;
  }
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
  p {
    margin-right: 0.5rem;
    margin-bottom: auto;
    sup {
      vertical-align: top;
      position: relative;
      top: -0.5em;
    }
  }
`;

export const IndentedModalListItem = styled(MDSText.BodyRegSm)`
  padding-left: 1rem;
  color: ${(p) => p.theme.midwestColors.textSecondary};
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  sup {
    margin-top: -6px;
  }
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
`;

export const ModalListItemChevron = styled.div`
  min-height: 0.25rem;
  min-width: 0.25rem;
  max-width: 0.25rem;
  max-height: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background: ${(p) => p.theme.midwestColors.sentimentNegativeMedium};
`;
export const ModalTextSidebar = styled.div`
  margin-top: 2rem;
  ${tablet} {
    margin-top: 0;
  }
`;

export const ModalSidebarList = styled.ul`
  padding-left: 0;
`;

export const LinkContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid ${(p) => p.theme.midwestColors.borderDisabled};
`;

export const ProductLink = styled(Link)`
  font-size: 0.75rem;
  font-weight: ${textBodyRegDefault.weight};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg:first-of-type {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0.5rem;
  }
`;

export const VideoLink = styled.span`
  font-size: 0.75rem;
  font-weight: ${textBodyRegDefault.weight};
  display: flex;
  align-items: center;
  text-align: left;

  > svg:first-of-type {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    color: ${(p) => p.theme.midwestColors.componentLinkHover};
  }
`;

export const LinkArrowContainer = styled.span`
  color: ${(p) =>
    p.theme.midwestColors
      .componentButtonColorBackgroundStrongDestructiveDefault};
  display: inline-flex;
  align-items: center;
`;

export const ClickOut = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
`;

export const SearchAdvisorButton = styled(MDSAnchorButton)`
  background-color: #fff;
  min-width: 16rem;
  border-radius: 6px;
  border: 2px solid ${(p) => p.theme.midwestColors.textSecondary};
  color: ${(p) => p.theme.midwestColors.textSecondary};
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  padding: 0.75rem;

  ${desktop} {
    padding: 1rem;
  }

  &:hover,
  &:focus {
    background-color: ${(p) =>
      p.theme.midwestColors.identityBrandTertiaryFaint};
  }
`;

export const ArrowSpan = styled.span`
  display: inline-flex;
`;
