'use client';

import { ModalCardSection } from './modal-card-section/modal-card-section';
import { ModalCardSectionCard } from './modal-card-section/modal-card-section.types';
import { svgIcons } from '@exploration/icons';

// --------------------------------------------------------------------------

const data = {
  title: 'How we can help',
  description:
    'Explore how a Thrivent financial advisor can help you build a financial plan that puts your money where your heart is.',
  cards: [
    {
      cardIcon: svgIcons.mapPoint,
      id: 'services-financial-planning',
      modalImage: {
        src: 'https://cdn.thrivent.com/ad/10/2c214cce42c28a658f9662292c55/financial-self-care.png',
        alt: 'Woman behind counter',
        height: 720,
        width: 467,
      },
      title: 'Financial Planning',
      description:
        'Financial planning is personal. That’s why our conversations focus on your unique values and goals.',
      listTitle: 'A financial advisor may talk to you about',
      listItems: [
        'Your values, financial needs, and goals',
        'How to build a financial plan with a strong foundation',
      ],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'The Psychology of Financial Planning',
          type: 'link',
          href: 'https://www.thrivent.com/insights/financial-planning/the-psychology-of-financial-planning',
        },
        {
          icon: 'play',
          text: 'Building Your Financial House',
          type: 'video',
          videoId: 'WikRxOh9EOPQiSZfLHzG4w',
        },
      ],
    },
    {
      id: 'services-budgeting-saving',
      cardIcon: svgIcons.piggyBankIcon,
      modalImage: {
        src: 'https://cdn.thrivent.com/97/f1/c15b153043c9b62670ec2c7ef05f/boy-learning-to-ride-with-dad.png',
        alt: 'Man with Children',
        height: 720,
        width: 467,
      },
      title: 'Budgeting & Saving',
      description:
        'Budgets are a powerful tool that help you align your everyday money decisions to your values.',
      listTitle: 'A financial advisor may talk to you about',
      listItems: ['Managing your cash flow', 'Paying down debt'],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Pay Yourself First: Save More with the 80/20 Budget',
          type: 'link',
          href: 'https://www.thrivent.com/insights/budgeting-saving/what-does-it-mean-to-pay-yourself-first',
        },
        {
          icon: 'chart',
          text: 'Try our free money coaching service',
          type: 'link',
          href: 'https://www.thrivent.com/generosity/money-canvas',
        },
      ],
    },
    {
      id: 'services-retirement-planning',
      cardIcon: svgIcons.beachChair,
      modalImage: {
        src: 'https://cdn.thrivent.com/3f/94/4cb27fd14e4f8c17b9ebc85b6c04/couple-smiling-in-kitchen.png',
        alt: 'Man shaking hands with an advisor',
        height: 720,
        width: 467,
      },
      title: 'Retirement Planning',
      description:
        'Whether you are nearing retirement or just starting out, we offer advice and tax-efficient saving strategies to help you achieve your goals.<sup>1</sup>',
      listTitle: 'A financial advisor may talk to you about',
      listItems: [
        'How you want to spend your retirement',
        'Your investment risk tolerance',
        'Types of investment accounts',
      ],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'The Ultimate Guide to Retirement by Age',
          type: 'link',
          id: 'services-financial-planning-link-1',
          href: 'https://www.thrivent.com/insights/retirement-planning/the-ultimate-guide-to-retirement-savings-by-age',
        },
        {
          icon: 'document',
          text: 'Try our retirement income match tool',
          id: 'services-financial-planning-link-2',
          type: 'link',
          href: 'https://income-match-assessment.apps.thrivent.com',
        },
      ],
    },
    {
      id: 'services-generosity-giving',
      cardIcon: svgIcons.handGiftIcon,
      modalImage: {
        src: 'https://cdn.thrivent.com/a0/cf/78aad1f44b3488fae8696abd9cec/thrivent-day4-producedonations-6928.png',
        alt: 'Thrivent Employees Packing Care Packages',
        height: 720,
        width: 467,
      },
      title: 'Generosity & Giving',
      description:
        'Giving can be an important and fulfilling aspect of your financial plan. We can provide you charitable giving expertise.',
      listTitle: 'A financial advisor may talk to you about',
      listItems: [
        'Different causes that you care about',
        'Tax-efficient options for charitable giving<sup>1</sup>',
      ],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Family Giving Strategies',
          type: 'link',
          href: 'https://www.thrivent.com/insights/generosity-giving/family-giving-strategies-getting-everyone-involved-in-living-generously',
        },
        {
          icon: 'document',
          text: 'A guide to charitable giving strategies',
          type: 'link',
          href: 'https://www.thrivent.com/insights/generosity-giving/a-guide-to-charitable-giving-strategies-their-tax-advantages',
        },
      ],
    },
    {
      id: 'services-investing',
      cardIcon: svgIcons.barGraphUp,
      modalImage: {
        src: 'https://cdn.thrivent.com/9d/c7/d85a5f044273ae1c3848969d81d8/father-son-basketball.png',
        alt: 'Man and Woman Hugging',
        height: 720,
        width: 467,
      },
      title: 'Investing',
      description:
        'We start by understanding the true purpose for the money you want to invest. Then we build a strategy to help achieve your goals.',
      listTitle: 'A financial advisor may talk to you about',
      listItems: [
        'Your investing goals and experience',
        'Factors that impact your risk tolerance',
      ],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Understanding Your Risk Tolerance',
          type: 'link',
          href: 'https://www.thrivent.com/insights/investing/why-understanding-your-risk-tolerance-can-lead-to-smarter-investments',
        },
      ],
    },
    {
      id: 'services-getting-insured',
      cardIcon: svgIcons.brandRefreshShield,
      modalImage: {
        src: 'https://cdn.thrivent.com/75/48/46e1b5d84700a115b48435949de5/mom-kissing-young-daughter.png',
        alt: 'Woman Signing Paper',
        height: 720,
        width: 467,
      },
      title: 'Getting Insured',
      description:
        'No one can predict the future, but we can help you prepare for unexpected surprises.',
      listTitle: 'A financial advisor may talk to you about',
      listItems: [
        'Protecting your income from unexpected losses',
        'Protecting your family’s financial future',
      ],
      sidebarTitle: 'Related resources',
      sidebarLinks: [
        {
          icon: 'document',
          text: 'Do You Need Life Insurance? Questions to Ask',
          type: 'link',
          href: 'https://www.thrivent.com/insights/life-insurance/do-i-need-life-insurance-questions-to-ask-yourself-and-your-family',
        },
        {
          icon: 'chart',
          text: 'Try our Term Life quote tool',
          type: 'link',
          href: 'https://term-life-insurance.apps.thrivent.com',
        },
      ],
    },
  ] as ModalCardSectionCard[],
};

// --------------------------------------------------------------------------

export const HowWeCanHelpSection = () => {
  return (
    <ModalCardSection
      title={data.title}
      description={data.description}
      cards={data.cards}
    />
  );
};
