'use client';

import { ModalCardSectionModal } from './modal-card-section-modal';
import {
  Article,
  ArticleButton,
  ArticleButtonText,
  ArticleContainer,
  ArticleImageContainer,
  CardIcon,
  ClickOut,
  Container,
  ModalContainer,
  Section,
} from './modal-card-section.style';
import {
  ModalCardSectionCard,
  ModalCardSectionProps,
} from './modal-card-section.types';
import { Icon } from '@exploration/icons';
import { SectionIntro } from '@exploration/shared-components';
import { Fragment, isValidElement, useState } from 'react';

// --------------------------------------------------------------------------

export const ModalCardSection = ({
  className = '',
  title,
  description,
  cards,
  belowCardContent,
}: ModalCardSectionProps) => {
  const [modalOpen, setModalOpen] = useState<string | null>(null);
  const [selectedCard, setSelectedCard] = useState<ModalCardSectionCard | null>(
    null,
  );

  const setData = (card: ModalCardSectionCard) => {
    setModalOpen(card.id);
    setSelectedCard(card);
  };

  const clearModalData = () => {
    setModalOpen(null);
    setSelectedCard(null);
  };

  return (
    <Section className={className}>
      {!!modalOpen && <ClickOut onClick={() => clearModalData()}></ClickOut>}
      <Container>
        {!!selectedCard && (
          <ModalCardSectionModal
            open={modalOpen === selectedCard.id}
            card={selectedCard}
            resetModal={() => clearModalData()}
          />
        )}
        <SectionIntro title={title} description={description} />
        <ModalContainer>
          <ArticleContainer $modalActive={!!modalOpen}>
            {cards?.map((card) => (
              <Fragment key={card.id}>
                <Article
                  onClick={() => setData(card)}
                  $modalActive={!!modalOpen}
                  aria-disabled={!!modalOpen}
                  aria-hidden={!!modalOpen}
                  disabled={!!modalOpen}
                  data-analytics-id={`${card.id}-modal-open`}
                >
                  <ArticleImageContainer>
                    <CardIcon>
                      <Icon icon={card.cardIcon} height={60} width={60} />
                    </CardIcon>
                  </ArticleImageContainer>
                  <ArticleButton key={card.id}>
                    <ArticleButtonText>{card.title}</ArticleButtonText>{' '}
                  </ArticleButton>
                </Article>
              </Fragment>
            ))}
          </ArticleContainer>
        </ModalContainer>
      </Container>
      {isValidElement(belowCardContent) && !!modalOpen && belowCardContent}
    </Section>
  );
};
