// Gets the offsetTop of an element relative to the body
// Used as an alternative to the offsetTop property which uses
// the closest positioned ancestor element
export function getOffsetTopRelativeToBody(element: HTMLElement) {
  let offsetTop = 0;
  let currentElement: HTMLElement | null = element;

  while (currentElement) {
    offsetTop += currentElement.offsetTop;
    currentElement = currentElement.offsetParent as HTMLElement | null;
  }

  return offsetTop;
}
