'use client';

import { useEffect } from 'react';

type ListenerFunction = (scrollY: number) => void;

export const useScrollY = (listener: ListenerFunction) => {
  useEffect(() => {
    function handleScroll() {
      listener(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial call to set the initial scroll position

    return () => window.removeEventListener('scroll', handleScroll);
  }, [listener]);
};
